// src/components/_common/Footer/index.js
import React from "react";
import Link from 'next/link';
// import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';

import styles from './css/footer.module.css';

import { CopyrightNote } from '../../_common/CopyrightNote';
import { IconPaths } from '../../../constants';
import { genAltText } from '../../../utils';

export const Footer = () => { 
  return (
    <footer id="footer" className={styles["ftr"]}>
      <div>
        <CopyrightNote variant="primary"/>
      </div>
      <div className={styles["ftr--n-l"]}>
        <div className={styles["ftr--for-businesses"]}>
          <Link href="/dla-firm" style={{ textDecoration: 'none', color: '#FBFBFF'}}>
            <FormattedMessage
              id = "ForBusinesses"
              defaultMessage="Dla firm"
            />
          </Link>
        </div>
        <div className={styles["ftr--terms-and-conditions"]}>
          <Link href="/regulamin" style={{ textDecoration: 'none', color: '#FBFBFF'}}>
            <FormattedMessage
              id = "TermsAndConditions"
              defaultMessage="Regulamin"
            />
          </Link>
        </div>
        <div className={styles["ftr--privacy-policy"]}>
          <Link href="/polityka-prywatnosci" style={{ textDecoration: 'none', color: '#FBFBFF'}}>
            <FormattedMessage
              id = "PrivacyPolicy"
              defaultMessage="Polityka prywatności"
            />
          </Link>  
        </div>
        <div className={styles["ftr--faq"]}>
          <Link href="/faq" style={{ textDecoration: 'none', color: '#FBFBFF'}}>
            <FormattedMessage
              id = "FAQ"
              defaultMessage="Najczęściej zadawane pytania"
            />
          </Link>  
        </div>
        <div className={styles["ftr--contact"]}>
          <Link href="/kontakt" style={{ textDecoration: 'none', color: '#FBFBFF'}}>
            <FormattedMessage
              id = "Contact"
              defaultMessage="Kontakt"
            />
          </Link>
        </div>
        <div className={styles["ftr--facebook"]}>
          <a href={process.env.NEXT_PUBLIC_BUUKI_FACEBOOK_FANPAGE_URL} target="_blank" rel="noopener noreferrer" className={styles["ftr--n-l-fb"]}>
            <Image src={IconPaths.facebookIcon} alt={genAltText(IconPaths.facebookIcon)} width={25} height={25} className={styles["ftr--n-l-fb-i"]} />
            <span className={styles["ftr--n-l-fb-t"]}>Facebook</span>
          </a>
        </div>
      </div>
    </footer>
  )
}
