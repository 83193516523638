import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Image from 'next/image';
import PropTypes from 'prop-types';

import styles from './css/card-details-modal.module.css';

import { Trans } from '@/utils/intlTranslations';
import { FaCheck, FaTimes, FaQuestion } from 'react-icons/fa';

export const CardDetailsModal = ({ headerTitle, card, open, handleClose, displayKeys }) => {    
    const [selectedRow] = useState(card);

    const setValue = (value) => {
        const boolMap = { 
            true: <FaCheck style={{ color: 'green', marginLeft: '10px', marginBottom: '10px' }} />, 
            false: <FaTimes style={{ color: 'red', marginLeft: '10px', marginBottom: '10px' }} />, 
            null: <FaQuestion style={{ color: 'gray', marginLeft: '10px', marginBottom: '10px' }} /> 
        };
        if (value in boolMap) return boolMap[value];
        if (value === '' || value == null) return <FaQuestion style={{ color: 'gray', marginLeft: '10px', marginBottom: '10px' }} />;
    
        // Sprawdzenie, czy wartość jest tablicą
        if (Array.isArray(value) && value.length > 0) {
            // Sprawdzenie, czy pierwszy element tablicy ma właściwość 'name'
            if ('name' in value[0]) {
                return value[0].name;
            }
        }

        // Sprawdzenie, czy wartość jest obiektem i ma właściwość 'name'
        if (value !== null && typeof value === 'object' && 'name' in value) {
            return value.name;
        }
    
        return value.toString();
    };  

    function getDetailsForKey(key, array) {
        const item = array.find(element => element.key === key);
        if (item && item.isImage) {
            return {
                width: item.imageSize.width,
                height: item.imageSize.height,
                customLabel: item.customLabel
            };
        }
        return null;
    }

    const imageKey = displayKeys.find(({ isImage }) => isImage)?.key;
    const imageWidth = getDetailsForKey(imageKey, displayKeys).width;
    const imageHeight = getDetailsForKey(imageKey, displayKeys).height;
    const imageCustomLabel = getDetailsForKey(imageKey, displayKeys).customLabel;

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="modal-title"
            PaperProps={{
                style: {
                  maxHeight: '80vh',
                  marginBottom: '20vh', // Dostosuj wartość zgodnie z wysokością twojego menu
                },
            }}
        >
            <DialogTitle id="modal-title">{headerTitle}</DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <div className={styles.detailsContainer}>
                    {selectedRow && displayKeys.map(({ key, isImage, imageSize, customLabel }, index) => {
                        const value = selectedRow[key];
                        if (!(key in selectedRow)) {
                            return null;
                        }

                        const label = customLabel ? customLabel : Trans(key);

                        return (
                            <div key={key+index}>
                                {isImage && typeof window !== 'undefined' && window.innerWidth < 767 ? (
                                    <div className={styles.imageContainer}>
                                        <img 
                                            src={`${value}?v=${new Date().getTime()}`}
                                            alt={label} 
                                            width={imageSize.width} 
                                            height={imageSize.height} 
                                            className={styles.imageBook}
                                        />
                                    </div>
                                ) : (
                                    !isImage && 
                                        <>
                                        <strong>{label}: </strong>
                                        {setValue(value)}
                                        </>
                                   
                                )}
                            </div>
                        );
                    })}
                </div>
                {typeof window !== 'undefined' && window.innerWidth > 767 && selectedRow[imageKey] && (
                    <div className={styles.imageContainer}>
                        <Image 
                            src={selectedRow[imageKey]} 
                            alt={imageCustomLabel}
                            width={imageWidth} 
                            height={imageHeight} 
                            className={styles.imageBook}
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Zamknij</Button>
            </DialogActions>
        </Dialog>
    );
}

// PropTypes
CardDetailsModal.propTypes = {
    headerTitle: PropTypes.string.isRequired,
    card: PropTypes.object,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    displayKeys: PropTypes.array.isRequired,
  // Definicje dla pozostałych właściwości...
};
