// src/lib/redux/bookOffers/ApiActionCreator.js
import { fetchData, fetchSuccess, fetchError } from './ApiAction';
import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3 });

const fetchBookOffers = (latitude, longitude, searchPhrase, page, pageSize) => async dispatch => {
  dispatch(fetchData());
  
  new Promise((resolve, reject) => {
    axios
      .get(`/api/book-offers`, {
        params: { 
          latitude, 
          longitude, 
          searchPhrase, //title, author, isbn ...
          page,
          pageSize
        },
      })
      .then((response) => {
        if (response.data) {
          dispatch(fetchSuccess(response.data));
        }
        resolve(response);
      })
      .catch((error) => {
        console.error('Błąd w fetchBookOffers: ', error);
        dispatch(fetchError(error));
        reject(error);
      });
  });
};

export default fetchBookOffers;
