// src/components/YourResults/index.js
import React, { useCallback, useState } from "react";
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import styles from './css/your-results.module.css';

import { ImagePaths } from '@/constants';
import { CardsGrid } from '@/components/CardsGrid';
import { NoResultsContent } from '@/components/_common/NoResultsContent';
import { AddBookFooter } from '@/components/AddBookFooter';

import debugLog from '@/helpers/debugLog'; 

export const YourResults = ({searchPhrase, booksData}) => {
    debugLog('YourResults bookOffersData', 'debug', booksData);
    // debugLog('YourResults searchPhrase', 'debug', searchPhrase);
    const router = useRouter();
    const [sortState, setSortState] = useState({
        sortField: 'created_at',
        sortDirection: 'desc',
    });

    // Selektor Reduxa
    // const { data: bookInformationData, loading:  bookInformationIsLoading, pagination:  bookInformationPagination} = useSelector((state) => state.bookInformation);
    const { data: bookOffersData, loading:  bookOffersIsLoading, pagination:  bookOffersPagination} = useSelector((state) => state.bookOffers);
    
    const handleReturnHomePageClick = useCallback(() => {
        router.push("/");
    }, [router]);

    return (
        <>
            <section className={styles["yr"]}>
                <div className={styles["yr--con"]}>
                    <div className={styles["yr--con-hdr"]}>
                        <h1>
                            <FormattedMessage
                                id = "YourResults"
                                defaultMessage="Wyniki wyszukiwania"
                            />
                            <sup className={styles["yr--con-hdr-pagination"]}>
                                {bookOffersPagination?.totalCount ? '(' + bookOffersPagination.totalCount.toLocaleString('pl-PL') + ')' : ''}
                            </sup>
                        </h1>
                    </div>
                    { 
                        bookOffersData?.length > 0 ?
                            <CardsGrid 
                                cardsGridData={booksData} 
                                dataReducer={'bookOffers'} 
                                searchPhrase={searchPhrase}
                                sortFieldAndDirection={sortState}
                            />
                        : 
                        <NoResultsContent 
                            resultImage={ImagePaths.YourResultsImg} 
                            resultHeader={'BooksNoFound'} 
                            resultText={'BooksNotFound'} 
                            resultButtonLable={'HomePage'}
                            resultHandleButtonClick={handleReturnHomePageClick}
                        />
                    }
                </div>
            </section>
            <AddBookFooter />
        </>
    )
}

// PropTypes
YourResults.propTypes = {
    bookOffersData: PropTypes.array,
    searchPhrase: PropTypes.string,
    booksData: PropTypes.array,
};