// src/components/_common/Loading/Loading.js
import React from "react";
import PropTypes from 'prop-types';

import styles from './css/loader.module.css';

const Loading = (props) =>  (
  <div style={{ display: props.isLoading ? 'flex' : 'none' }} className={styles["ldg--mod"]}>
    <div className={styles["ldg-mod-con"]}>
      <div className={styles["ldg-mod-l"]}></div>
      <div className={styles["ldg-mod-t"]}>
        {props.isLoadingText}
      </div>
    </div>
  </div>
)

// PropTypes
Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingText: PropTypes.string.isRequired,
}

export default Loading;

// https://react-bootstrap.github.io/components/spinners/
// import { Spinner } from "react-bootstrap";

// function Loading (props) {
//   return (
//     <Spinner animation="border" role="status">
//       <span className={styles["visually-hidden"]}>Loading...</span>
//     </Spinner>
//   )
// }

// export default Loading;