// src/lib/redux/similarBookOffers/ApiActionCreator.js
import { fetchData, fetchSuccess, fetchError } from './ApiAction';
import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3 });

const fetchSimilarBookOffer = (latitude, longitude, category, id, page) => async dispatch => {
  dispatch(fetchData());
  
  new Promise((resolve, reject) => {
    axios
      .get(`/api/book-offers/similar-book-offers`, {
        params: { 
          latitude, 
          longitude, 
          category, 
          id, 
          page
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch(fetchSuccess(response.data));
        }
        resolve(response);
      })
      .catch((error) => {
        console.error('Błąd w fetchSimilarBookOffer: ', error);
        dispatch(fetchError(error));
        reject(error);
      });
  });
};

export default fetchSimilarBookOffer;
