// src/components/_common/QrCodeScanner/index.js
'use client';
import React from 'react';
import { Html5QrcodeScanType, Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './css/qr-code-scanner.module.css';

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
    let config = {
        fps: 10,
        qrbox: { width: 250, height: 250 },
        aspectRatio: 1.0,
        disableFlip: true,
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        facingMode: { exact: 'environment' },
      };
    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }
    return config;
};

const QrCodeScanner = (props) => {

    useEffect(() => {
        // when component mounts
        const config = createConfig(props);
        const verbose = props.verbose === true;
        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
        html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, [props]);

    return ( 
        <div id={qrcodeRegionId} className={`${styles['html5qr-code-full-region']}`} />
    )
};

export default QrCodeScanner;

// QrCodeScanner
QrCodeScanner.propTypes = {
    verbose: PropTypes.bool,
    qrCodeSuccessCallback: PropTypes.func,
    qrCodeSuccessCallback: PropTypes.func,
    qrCodeErrorCallback: PropTypes.func,
    resultHandleButtonClick: PropTypes.string,
    verticalLayout: PropTypes.bool,
};