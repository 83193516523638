import React, { useState, useEffect } from 'react';
import Link from 'next/link';

import styles from './css/cookie-consent-popup-notice.module.css';

const CookieConsentPopupNotice = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const hasConsented = localStorage.getItem('cookieConsent');
        if (!hasConsented) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className={`${styles['popupStyle']}`}>
            <div className={`${styles['popupContentStyle']}`}>
                <h1>Polityka Cookies i RODO</h1>
                <p>
                    Nasza strona wykorzystuje pliki cookies w celu zapewnienia najwyższej jakości usług. 
                    Korzystając z naszej strony, zgadzasz się na używanie cookies i akceptujesz naszą 
                    <Link href="/polityka-prywatnosci"> Politykę Prywatności</Link>. 
                    Przetwarzamy Twoje dane zgodnie z RODO, dbając o ich bezpieczeństwo i prywatność.
                </p>
                <button onClick={handleAccept} className={`${styles['buttonStyle']}`}>
                    Akceptuję
                </button>
            </div>
        </div>
    );
};

export default CookieConsentPopupNotice;
