// src/components/_common/CopyrightNote/index.js
import React from 'react';
import PropTypes from 'prop-types';

import styles from './css/copyrightNote.module.css';

import { copyNote } from '../../../config/copyrightNote';
// import { appVer } from '../../../config/copyrightNote';

export const CopyrightNote = (props) => {
    const variantClass = props.variant === 'primary' ? styles.primary : '';

    return (
        <span className={`${styles.container} ${variantClass}`}>
            {copyNote.copyright} {copyNote.year} {copyNote.note} 
            {/* {"\n"+appVer.version} {appVer.release} */}
        </span>
    );
}

// PropTypes
CopyrightNote.propTypes = {
    variant: PropTypes.string.isRequired,
};
