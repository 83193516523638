// src/lib/redux/bookOffers/ApiAction.js
import ACTION_TYPES from './ActionTypes';

export const fetchData = () => ({
  type: ACTION_TYPES.BOOK_OFFERS_PENDING,
});

export const fetchSuccess = (data) => ({
  type: ACTION_TYPES.BOOK_OFFERS_SUCCESS,
  payload: data,
});

export const fetchError = (error) => ({
  type: ACTION_TYPES.BOOK_OFFERS_ERROR,
  payload: error,
});