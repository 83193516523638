// src/navigation/MainNavBar/MainNavBar.js
"use client";

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { signOut, useSession } from "next-auth/react";
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { FaTachometerAlt, FaHeart, FaRegCommentDots, FaMapMarkerAlt, FaSignOutAlt, FaHistory } from 'react-icons/fa';

import styles from './css/main-navbar.module.css';

import { FlagPaths, IconPaths, LogoPaths } from '@/constants';
import { Trans } from '@/utils/intlTranslations';
import { genAltText } from '@/utils';
import { useLanguage } from '@/contexts/LanguageContext';
import { useGeolocation } from '@/contexts/GeolocationContext';
import { enterpriseCategoryItems } from '@/constants/mappings';
import FloatingMenu from '@/navigation/FloatingMenu';
// import fetchBookInformation from '@//lib/redux/bookInformation/ApiActionCreator';
import fetchBookOffers from '@//lib/redux/bookOffers/ApiActionCreator';
import { clearSession } from '@/lib/redux/sessionReducer/Actions';
import Loading from "@/components/_common/Loading/Loading"; 
import { refreshSession } from '@/lib/api/refreshSession';

import debugLog from '@/helpers/debugLog'; 

const MainNavBar = (props) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [langName, setLangName] = useState('PL');
  const [langFlag, setFlag] = useState(FlagPaths.plFlag);
  const [profilePictureUrl, setProfilePictureUrl] = useState(IconPaths.navbarProfileIcon);
  const { data: session, status } = useSession();
  const {locale, selectLanguage, userSelectedLanguage } = useLanguage();
  const router = useRouter();
  const languageSettings = useMemo(() => ({
    en: { langName: 'EN', flag: FlagPaths.enFlag, langCode: 'en' },
    pl: { langName: 'PL', flag: FlagPaths.plFlag, langCode: 'pl' },
    de: { langName: 'DE', flag: FlagPaths.deFlag, langCode: 'de' },
    uk: { langName: 'UA', flag: FlagPaths.uaFlag, langCode: 'uk' },
  }), []);
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 991); // useState(window.innerWidth < 768);
  const { coordinates, handleGeolocationClick } = useGeolocation();
  const [loggingOut, setLoggingOut] = useState(false);
  const dispatch = useDispatch();
  // const search = window.location.search;
  // const hasSearchParam = search.includes('wyszukaj=');

  // Selektor Reduxa
  const sessionData = useSelector((state) => state.session.user);

  // Funkcja używana w funkcji routerReplace, zmieniająca ścieżkę w zależności od języka
  const findPathForLocale = (currentPath, lang, categoryItems) => {
    // Znajdź obiekt zawierający currentPath niezależnie od języka
    const itemWithCurrentPath = categoryItems.find(item =>
      Object.values(item.path).includes(currentPath)
    );
  
    // Jeśli znaleziono, zwróć ścieżkę dla podanego języka
    return itemWithCurrentPath ? itemWithCurrentPath.path[lang] : undefined;
  }

  // Wywołanie akcji redux
  const handleClearSession = () => {
    dispatch(clearSession());
  };

  const handleDashboardPage = (evt) => {
    evt.preventDefault();
    router.push('/panel')
  }

  const handleInputChange = (evt) => {
    setSearchInputValue(evt.target.value);
    if (evt.target.value === '') {
      // Aktualizuj URL, dodając parametr wyszukiwania
      dispatch(fetchBookOffers(coordinates.latitude, coordinates.longitude, evt.target.value, 1));
      router.push(`/?wyszukaj=${encodeURIComponent(evt.target.value)}`);
    }
  };

  const handleReturnHomePageClick = useCallback(() => {
    router.push("/");
  }, [router]);

  const handleLanguageChange = useCallback((lang) => {
    debugLog('MainNavBar handleLanguageChange', 'debug', lang);
    const settings = languageSettings[lang] || languageSettings['pl']; // Domyślnie 'pl'
    setLangName(settings.langName);
    setFlag(settings.flag);
    selectLanguage(settings.langCode, true);
    routerReplace(settings.langCode);
  }, [selectLanguage, languageSettings, routerReplace]); // Jeśli selectLanguage lub inna część context zmienia się między renderowaniami, musisz dodać ją do tablicy zależności.

  const handleSearchSubmit = (evt) => {
    evt.preventDefault(); // Zapobiega domyślnej akcji przeglądarki dla formularza, zapobiega odświeżeniu strony
    onSearch(searchInputValue);
  };

  const handleSignInPage = useCallback(() => {
    if (status != "authenticated") {
      router.push('/zaloguj')
    }
  }, [router, status]);

  const handleFooterMenuPage = useCallback((page) => {
    const pagesWithRoutes = ['dla-firm', 'regulamin', 'polityka-prywatnosci', 'faq', 'kontakt'];
    if (pagesWithRoutes.includes(page)) {
        router.push(`/${page}`);
    } else {
        router.push('/');
    }
  }, [router]);

  const handleNavDropdownClick = useCallback((page) => {
    router.push(`/${page}`);
  }, [router]);

  const handleSignOut = () => {
    setLoggingOut(true);
    handleClearSession();
    setTimeout(() => {
        signOut({ callbackUrl: '/' });
    }, 2000); // 2 sekundy
  }

  const intl = useIntl();
  const _noSearchDataIntl = intl.formatMessage({ id: "NoSearchData" });
  // const _noInformationFoundIntl = intl.formatMessage({ id: "NoInformationFound" });
  const onSearch = (searchTerm) => {
      // console.log(`Wyszukiwanie: ${searchTerm}`);
      if ( searchTerm.length === 0 ) {
          toast.error(_noSearchDataIntl);
          // dispatch(fetchBookInformation(coordinates.latitude, coordinates.longitude, searchTerm, 1));

          // Aktualizuj URL, dodając parametr wyszukiwania
          // router.push(`/?wyszukaj=${encodeURIComponent(searchTerm)}`);
      }else {
          // toast.error(_noInformationFoundIntl);
          // dispatch(fetchBookInformation(coordinates.latitude, coordinates.longitude, searchTerm, 1));
          dispatch(fetchBookOffers(coordinates.latitude, coordinates.longitude, searchTerm, 1));

          // Aktualizuj URL, dodając parametr wyszukiwania
          router.push(`/?wyszukaj=${encodeURIComponent(searchTerm)}`);
          // setInputValue('');
      }
  };

  // Zaktualizuj URL bez przeładowania strony
  const routerReplace = useCallback((lang) => {
    const currentPath = router.asPath; // ścieżka
    // Pobierz ścieżkę dla danego lang
    const targetPath = findPathForLocale(currentPath, lang, enterpriseCategoryItems);
    // console.log('MainNavBar routerReplace lang currentPath targetPath userSelectedLanguage => ', lang, currentPath, targetPath, userSelectedLanguage)
    if (targetPath) {
        // debugLog('MainNavBar targetPath', 'debug', targetPath);
        // Zaktualizuj URL bez przeładowania strony
        if (typeof window !== 'undefined') {
            router.push(targetPath, undefined, { shallow: true });
            // router.replace(targetPath)
        }
    }
  }, [router]);

  // Uruchamia się po pierwszym renderowaniu komponentu oraz za każdym razem, 
  // gdy zmienia się wartość zależności locale.
  useEffect(() => {
      const settings = languageSettings[locale] || languageSettings['pl']; // Domyślnie 'pl'
      setLangName(settings.langName);
      setFlag(settings.flag);
      selectLanguage(settings.langCode, true);
  }, [locale, languageSettings, selectLanguage]);

  // Uruchamia się po pierwszym renderowaniu komponentu oraz za każdym razem, 
  // gdy zmieniają się wartości podanych zależności.
  // Aktualizuje URL zdjęcia profilowego
  useEffect(() => {
    // debugLog('userPicture => MainNavBar sessionData', 'debug', sessionData);
    // debugLog('userPicture => MainNavBar session', 'debug', session);

    if (session && status === "authenticated") {
      // const newProfilePictureUrl = sessionData?.picture ? sessionData.picture : session?.user?.picture ? session.user.picture : IconPaths.navbarProfileIcon;
      const newProfilePictureUrl = session?.user?.picture ? session.user.picture : IconPaths.navbarProfileIcon;
      if (profilePictureUrl !== newProfilePictureUrl) {
        if (newProfilePictureUrl) {
          fetch(`${newProfilePictureUrl}?v=${new Date().getTime()}`)
            .then(response => {
                // Sprawdzanie, czy typ MIME odpowiedzi pasuje do typu obrazu
                const contentType = response.headers.get("content-type");
                if (!contentType || !contentType.startsWith("image/")) {
                    console.log("MainNavBar => newProfilePictureUrl => MIME type mismatch: ", newProfilePictureUrl);
                    throw new Error('Odpowiedź nie jest obrazem.');
                }
        
                if (!response.ok) {
                    console.log("MainNavBar => newProfilePictureUrl => Network error: ", newProfilePictureUrl);
                    throw new Error('Network response was not ok');
                }
        
                // console.log("MainNavBar => newProfilePictureUrl => Image found: ", newProfilePictureUrl);
                setProfilePictureUrl(newProfilePictureUrl);
            })
            .catch(() => {
                console.log("MainNavBar => newProfilePictureUrl => Setting default image: ", newProfilePictureUrl);
                setProfilePictureUrl(IconPaths.navbarProfileIcon); // Ustawienie domyślnego obrazu w przypadku błędu
            });
        }
      }
    } else if (!session && profilePictureUrl !== IconPaths.navbarProfileIcon) {
      setProfilePictureUrl(IconPaths.navbarProfileIcon);
    }
  }, [session, status, profilePictureUrl, sessionData]); // Nie zawieraj profilePictureUrl w zależnościach

  // Aktualizacja stanu na podstawie parametru zapytania URL
  useEffect(() => {
    if (router.query.wyszukaj) {
      setSearchInputValue(router.query.wyszukaj);
    }
  }, [router.query.wyszukaj]);

  // Nasłuchiwanie zmian rozmiaru okna
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991); //768
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // debugLog
  useEffect(() => {
    debugLog('MainNavBar session', 'debug', session);
    debugLog('MainNavBar session status', 'debug', status);
    debugLog('MainNavBar session sessionData', 'debug', sessionData);

    debugLog('MainNavBar session localStorage preLoginRoute', 'debug', localStorage.getItem('preLoginRoute'));
    debugLog('MainNavBar session localStorage shouldRedirect', 'debug', localStorage.getItem('shouldRedirect'));
    debugLog('MainNavBar session localStorage postLoginAction', 'debug', localStorage.getItem('postLoginAction'));
    debugLog('MainNavBar session localStorage actionId', 'debug', localStorage.getItem('actionId'));
  }, [status, session, sessionData]);

  const DesktopMenu = () => {
    return (
      status === "authenticated" ?
      <>
        {/* profilePictureUrl */}
        {/* <Image 
          src={profilePictureUrl} 
          alt={genAltText(profilePictureUrl)} 
          width={22} height={22} 
          className={`${styles['nvn--nav-profile-img']} ${styles['nvn--navbar-con-menu-desktop']}`}
          style={{marginRight: "-2px"}} 
          onClick={(evt) => handleDashboardPage(evt)}
        /> */}
        <img 
          src={profilePictureUrl} 
          // alt={genAltText(profilePictureUrl)} 
          alt={'ico'} 
          width={22} height={22} 
          className={`${styles['nvn--nav-profile-img']} ${styles['nvn--navbar-con-menu-desktop']}`}
          style={{marginRight: "-2px"}} 
          onClick={(evt) => handleDashboardPage(evt)}
        />
        {/* Dashboard */}
        <NavDropdown 
          title={sessionData?.name ? sessionData.name : <FormattedMessage id = "Dashboard" defaultMessage="Dashboard" /> } 
          id="basic-nav-dropdown" align="end"
          className={`${styles['nvn--nav-ll']} ${styles['nvn--navbar-con-menu-desktop']}`}
        >
          {/* Dashboard */}
          <NavDropdown.Item 
            active={window.location.pathname === "/panel"} 
            onClick={() => handleNavDropdownClick('panel')} 
            href=""
          >
            <FaTachometerAlt width={24} height={18} className={styles["nvn--navbar-d-ico"]} />
            <FormattedMessage
              id = "Dashboard"
              defaultMessage="Dashboard"
            />
          </NavDropdown.Item>

          <NavDropdown.Divider />

          {/* Moje lokalizacje */}
          <NavDropdown.Item 
            active={window.location.pathname === "/moje-lokalizacje"} 
            onClick={() => handleNavDropdownClick('moje-lokalizacje')} 
            href=""
          >
            <FaMapMarkerAlt width={24} height={18} className={styles["nvn--navbar-d-ico"]} />
            <FormattedMessage
              id = "MyLocations"
              defaultMessage="Moje lokalizacje"
            />
          </NavDropdown.Item>

          <NavDropdown.Divider />

          {/* Ulubione */}
          <NavDropdown.Item 
            active={window.location.pathname === "/ulubione"} 
            onClick={() => handleNavDropdownClick('ulubione')} 
            href=""
          >
            <FaHeart width={24} height={18} className={styles["nvn--navbar-d-ico"]} />
            <FormattedMessage
              id = "Favorites"
              defaultMessage="Ulubione"
            />
          </NavDropdown.Item>

          <NavDropdown.Divider />

          {/* Moje opinie */}
          <NavDropdown.Item 
            active={window.location.pathname === "/moje-opinie"} 
            onClick={() => handleNavDropdownClick('moje-opinie')} 
            href=""
          >
            <FaRegCommentDots width={24} height={18} className={styles["nvn--navbar-d-ico"]} />
            <FormattedMessage
              id = "MyOpinions"
              defaultMessage="Moje opinie"
            />
          </NavDropdown.Item>

          <NavDropdown.Divider />

          {/* Ostatnio przeglądane */}
          <NavDropdown.Item 
            active={window.location.pathname === "/ostatnio-przegladane"} 
            onClick={() => handleNavDropdownClick('ostatnio-przegladane')} 
            href=""
          >
            <FaHistory width={24} height={18} className={styles["nvn--navbar-d-ico"]} />
            <FormattedMessage
              id = "RecentlyViewed"
              defaultMessage="Ostatnio przeglądane"
            />
          </NavDropdown.Item>

          <NavDropdown.Divider />
          <NavDropdown.Divider />

          {/* Wyloguj */}
          <NavDropdown.Item 
            active={window.location.pathname === "/"} 
            onClick={() => handleSignOut()} 
            href=""
          >
            <FaSignOutAlt width={24} height={18} className={styles["nvn--navbar-d-ico"]} />
            <FormattedMessage
              id = "LogOut"
              defaultMessage="Wyloguj"
            />
          </NavDropdown.Item>
        </NavDropdown>

        {/* Dashboard */}
        {/* <Link href="/dashboard" passHref style={{textDecoration: 'none'}}>
          <Nav.Link 
            href="/dashboard" 
            as="div" 
            className={`${styles['nvn--nav-l']} ${styles['nvn--nav-l-udl']} ${styles['nvn--navbar-con-menu-desktop']}`}
            aria-current={window.location.pathname === "/dashboard" ? "page" : undefined}
          >
            { 
              sessionData?.name ? sessionData.name : <FormattedMessage id = "Dashboard" defaultMessage="Dashboard" /> 
            }
          </Nav.Link>
        </Link> */}
        {/* Favorites */}
        {/* <Link href="/ulubione" passHref style={{textDecoration: 'none'}}>
          <Nav.Link 
            href="/favorites"
            as="div" 
            className={`${styles['nvn--nav-l']} ${styles['nvn--nav-l-udl']} ${styles['nvn--navbar-con-menu-desktop']}`}
            aria-current={window.location.pathname === "/ulubione" ? "page" : undefined}
          >
            <FormattedMessage
                id = "Favorites"
                defaultMessage="Ulubione"
            />
          </Nav.Link>
        </Link> */}
         {/* moje-lokalizacje */}
         {/* <Link href="/moje-lokalizacje" passHref style={{textDecoration: 'none'}}>
          <Nav.Link 
            href="/moje-lokalizacje" 
            as="div" 
            className={`${styles['nvn--nav-l']} ${styles['nvn--nav-l-udl']} ${styles['nvn--navbar-con-menu-desktop']}`} 
            aria-current={window.location.pathname === "/moje-lokalizacje" ? "page" : undefined}
          >
            <FormattedMessage
              id = "MyLocations"
              defaultMessage="Moje Lokalizacje"
            />
          </Nav.Link>
        </Link> */}
        
        {/* moj-zbior */}
        <Link href="/moj-zbior" passHref style={{textDecoration: 'none'}}>
          <Nav.Link 
            href="/moj-zbior" 
            as="div" 
            className={`${styles['nvn--nav-l']} ${styles['nvn--nav-l-ftd']} ${styles['nvn--navbar-con-menu-desktop']}`} 
            aria-current={window.location.pathname === "/moj-zbior" ? "page" : undefined}
          >
            <FormattedMessage
              id = "MyOffers"
              defaultMessage="Mój zbiór"
            />
          </Nav.Link>
        </Link>
      </>
      :
      <>
        {/* profilePictureUrl */}
        {/* <Image 
          // src={profilePictureUrl}
          src={`${profilePictureUrl}?v=${new Date().getTime()}`}
          alt={genAltText(profilePictureUrl)} 
          width={24} height={24} 
          className={`${styles['nvn--nav-profile-img']} ${styles['nvn--navbar-con-menu-desktop']}`}
          onClick={() => handleSignInPage()}
        /> */}
        <img 
          // src={profilePictureUrl}
          // src={`${profilePictureUrl}?v=${new Date().getTime()}`}
          // alt={genAltText(profilePictureUrl)} 
          src={IconPaths.navbarProfileIcon}
          alt={genAltText(IconPaths.navbarProfileIcon)} 
          width={24} height={24} 
          className={`${styles['nvn--nav-profile-img']} ${styles['nvn--navbar-con-menu-desktop']}`}
          onClick={() => handleSignInPage()}
        />
        {/* LogIn */}
        <Link href="/zaloguj" passHref style={{textDecoration: 'none'}} className={`${styles['nvn--navbar-con-menu-desktop']}`}>
          <Nav.Link 
            href="/zaloguj" 
            as="div" 
            className={`${styles['nvn--nav-l']} ${styles['nvn--nav-l-ftd']}`}
          >
            <FormattedMessage
                id = "LogIn"
                defaultMessage="Zaloguj się"
            />
          </Nav.Link>
        </Link>
      </>
    );
  }

  const ToggleMenu = () => {
    return (
      <div className={`${styles['nvn--navbar-con-menu-mobile-con']}`}>
        <div className={`${styles['nvn--navbar-con-menu-mobile-con-item']}`} onClick={() => handleFooterMenuPage('dla-firm')}>
          <FormattedMessage
            id = "ForBusinesses"
            defaultMessage="Dla firm"
          />
          <Image src={IconPaths.arrowDropRightIcon} alt={genAltText(IconPaths.chevronArrowRoundIcon)} width={18} height={18} />
        </div>
        <div className={`${styles['nvn--navbar-con-menu-mobile-con-item']}`} onClick={() => handleFooterMenuPage('regulamin')}>
          <FormattedMessage
            id = "TermsAndConditions"
            defaultMessage="Regulamin"
          />
          <Image src={IconPaths.arrowDropRightIcon} alt={genAltText(IconPaths.chevronArrowRoundIcon)} width={18} height={18} />
        </div>
        <div className={`${styles['nvn--navbar-con-menu-mobile-con-item']}`} onClick={() => handleFooterMenuPage('polityka-prywatnosci')}>
          <FormattedMessage
            id = "PrivacyPolicy"
            defaultMessage="Polityka prywatności"
          />
          <Image src={IconPaths.arrowDropRightIcon} alt={genAltText(IconPaths.chevronArrowRoundIcon)} width={18} height={18} />
        </div>
        <div className={`${styles['nvn--navbar-con-menu-mobile-con-item']}`} onClick={() => handleFooterMenuPage('faq')}>
          <FormattedMessage
            id = "FAQ"
            defaultMessage="Najczęściej zadawane pytania"
          />
          <Image src={IconPaths.arrowDropRightIcon} alt={genAltText(IconPaths.chevronArrowRoundIcon)} width={18} height={18} />
        </div>
        <div className={`${styles['nvn--navbar-con-menu-mobile-con-item']}`} onClick={() => handleFooterMenuPage('kontakt')}>
          <FormattedMessage
            id = "Contact"
            defaultMessage="Kontakt"
          />
          <Image src={IconPaths.arrowDropRightIcon} alt={genAltText(IconPaths.chevronArrowRoundIcon)} width={18} height={18} />
        </div>
      </div>
    )
  }

  const LanguageSelect = () => {
    return (
      <NavDropdown 
        title={<><Image src={langFlag} alt={genAltText(langFlag)} width={24} height={18} className={styles["nvn--navbar-f-ico"]} />{langName}</>} 
        id="basic-nav-dropdown" align="end"
        className={`${styles['nvn--navbar-con-menu-desktop']}`}
      >
        <NavDropdown.Item active={langName === 'EN'} onClick={() => handleLanguageChange('en')} href="#"><Image src={FlagPaths.enFlag} alt={genAltText(FlagPaths.enFlag)} width={24} height={18} className={styles["nvn--navbar-f-ico"]} />
          <FormattedMessage
            id = "English"
            defaultMessage="Angielski"
          />
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item active={langName === 'DE'} onClick={() => handleLanguageChange('de')} href="#"><Image src={FlagPaths.deFlag} alt={genAltText(FlagPaths.deFlag)} width={24} height={18} className={styles["nvn--navbar-f-ico"]} />
          <FormattedMessage
            id = "German"
            defaultMessage="Niemiecki"
          />
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item active={langName === 'PL'} onClick={() => handleLanguageChange('pl')} href="#"><Image src={FlagPaths.plFlag} alt={genAltText(FlagPaths.plFlag)} width={24} height={18} className={styles["nvn--navbar-f-ico"]} />
          <FormattedMessage
            id = "Polish"
            defaultMessage="Polski"
          />
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item active={langName === 'UA'} onClick={() => handleLanguageChange('uk')} href="#"><Image src={FlagPaths.uaFlag} alt={genAltText(FlagPaths.uaFlag)} width={24} height={18} className={styles["nvn--navbar-f-ico"]} />
          <FormattedMessage
            id = "Ukrainian"
            defaultMessage="Ukraiński"
          />
        </NavDropdown.Item>
      </NavDropdown>
    )
  }

  // Spersonalizowane ikony hamburgera
  const NavbarTogglerIconOpen = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
        <path d="M7.91406 11.0859H30.0807" stroke="#000732" strokeWidth="3.16667" strokeLinecap="round"/>
        <path d="M7.91406 19H30.0807" stroke="#000732" strokeWidth="3.16667" strokeLinecap="round"/>
        <path d="M7.91406 26.9141H30.0807" stroke="#000732" strokeWidth="3.16667" strokeLinecap="round"/>
      </svg>
    );
  };

  const NavbarTogglerIconClose = () => {
    return (
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_1_4)">
        <path d="M8.25724 9.49975C7.91425 9.15677 7.91425 8.60053 8.25724 8.25737C8.60022 7.91421 9.15646 7.91421 9.49962 8.25737L19.0849 17.8426L28.6701 8.25737C29.0131 7.91421 29.5693 7.91421 29.9127 8.25737C30.2556 8.60035 30.2556 9.15677 29.9127 9.49975L20.3271 19.0848L29.9125 28.6702C30.2555 29.0132 30.2555 29.5695 29.9125 29.9126C29.5693 30.2558 29.0131 30.2558 28.6699 29.9126L19.0847 20.3274L9.49962 29.9126C9.15664 30.2558 8.6004 30.2558 8.25724 29.9126C7.91425 29.5696 7.91425 29.0134 8.25724 28.6702L17.8425 19.0848L8.25724 9.49975Z" fill="#000732"/>
        </g>
        <path d="M7.76226 9.99473L7.76227 9.99473L16.8525 19.0848L7.76226 28.1753L7.76213 28.1754C7.14603 28.7918 7.14584 29.7912 7.76226 30.4076C8.37868 31.024 9.37814 31.0243 9.9946 30.4076C9.99464 30.4076 9.99468 30.4075 9.99472 30.4075L19.0847 21.3173L28.1749 30.4076C28.7915 31.0241 29.7909 31.0241 30.4075 30.4076L30.4076 30.4075C31.0237 29.791 31.0239 28.7917 30.4075 28.1753L21.317 19.0848L30.4076 9.99474L30.4076 9.99473C31.024 9.37838 31.024 8.37874 30.4076 7.7624L30.4075 7.76227C29.791 7.14605 28.7915 7.14568 28.175 7.76253L19.0849 16.8527L9.99459 7.7624C9.37814 7.14594 8.37858 7.14575 7.76213 7.76253C7.14603 8.37895 7.14584 9.37831 7.76226 9.99473Z" stroke="#000732" strokeWidth="1.4"/>
        <defs>
        <filter id="filter0_i_1_4" x="6.6" y="6.6" width="24.9699" height="28.97" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_4"/>
        </filter>
        </defs>
      </svg>
    );
  };

  const UserGeoLocation = () => {
    return (
      coordinates.latitude && coordinates.longitude ?
      <Image 
          src={IconPaths.locationIcon} 
          alt={genAltText(IconPaths.locationIcon)} 
          width={24} height={24} 
          className={`${styles['nvn--nav-geo-user-img']} ${styles['nvn--navbar-con-menu-desktop---']}`}
          onClick={() => handleGeolocationClick()}
      />
      :
      <Image 
        src={IconPaths.noLocationIcon} 
        alt={genAltText(IconPaths.noLocationIcon)} 
        width={24} height={24} 
        className={`${styles['nvn--nav-geo-user-img']} ${styles['nvn--navbar-con-menu-desktop----']}`}
        onClick={() => handleGeolocationClick()}
      />
    )
  }

  return (
      <>
        {/* expand="md" */}
        <Navbar bg="light" variant="light" expand="lg" className={`${styles['nvn--navbar-con']}`}>
          {/* Brand Logo */}
          <Navbar.Brand href="/" onClick={(e) => { e.preventDefault(); handleReturnHomePageClick();}} className={"main-navbar-brand " + styles['navbar-brand']}>
            <Image  
              src={LogoPaths.brandLogo} 
              alt={genAltText(LogoPaths.brandLogo)}
              width={37}
              height={42}
              className={"d-inline-block align-top"}
            /> 
            <div className={styles["nvn--navbar-brand-text"]}>
              {process.env.NEXT_PUBLIC_APP_NAME}
                  <div className={styles["nvn--navbar-b-n"]}>
                    {
                      process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME.startsWith('Buuki.pl') && 
                      <span className={styles["nvn--navbar-b-n-c-p"]} style={{textAlign: 'right', display: 'block'}}>
                        Dev Build
                      </span>
                    }
                  </div>
            </div>
          </Navbar.Brand>
          {/* Hamburger Menu Toggle Button */}
          <Navbar.Toggle 
            aria-controls="responsive-navbar-nav"
            className={`${styles['navbar-toggler-custom-icon']}`}
            onClick={() => setExpanded(!expanded)}
          >
            {isMobile && <UserGeoLocation />}
            {expanded ? <NavbarTogglerIconClose /> : <NavbarTogglerIconOpen />}
          </Navbar.Toggle>

          {/* Responsive Navbar Navigation */}
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" style={{flexgrow: '0 !important'}}>

            {/* FormControl/Search Form */}
            {
              router.asPath !== '/' && router.asPath !== '/zaloguj' ?
              <Nav className={`mr-auto ${styles['nvn--navbar-s']}`}>
                <Form className={"input-group"} onSubmit={handleSearchSubmit}>
                  <FormControl 
                      type="text" 
                      value={searchInputValue} 
                      onChange={handleInputChange} 
                      placeholder={Trans('WhatAreYouLookingForPlaceholder')} 
                      className={`mr-sm-2 ${styles['nvn--navbar-s-i']} ${styles['nvn--custom-form-control']}`} 
                  />
                  <Image 
                      src={IconPaths.searchIcon} 
                      alt={genAltText(IconPaths.searchIcon)} 
                      width={20} height={20} 
                      className={`${styles['nvn--navbar-s-ico']} ${styles['nvn--custom-form-control']}`} 
                  />
                  <Button type="submit" variant="outline-success" className={`${styles['nvn--navbar-s-btn']} ${styles['nvn--custom-form-control']}`}>
                      <FormattedMessage id="Search" defaultMessage="Szukaj" />
                  </Button>
                </Form>
              </Nav>
              :''
            }

            {/* Toggle Menu/Desktop Menu */}
            <Nav className={`${styles["nvn--navbar-con-menu"]}`}>
              {/* Toggle Menu */}
              {/* Układ dla urządzeń mobilnych */}
              <div className={`${styles['nvn--navbar-con-menu-mobile']}`}>
                {/* Toggle Menu */}
                <ToggleMenu />
              </div>

              {/* Desktop Menu */}
              {/* Układ dla urządzeń desktop */}
              <DesktopMenu />
              
              {/* ForBusinesses */}
              <Link href="/dla-firm" passHref style={{textDecoration: 'none'}}>
                <Nav.Link 
                  href="/dla-firm" 
                  as="div" 
                  className={`${styles['nvn--nav-l']} ${styles['nvn--nav-l-udl']} ${styles['nvn--navbar-con-menu-desktop']}`} 
                  aria-current={window.location.pathname === "/dla-firm" ? "page" : undefined}
                >
                  <FormattedMessage
                    id = "ForBusinesses"
                    defaultMessage="Dla firm"
                  />
                </Nav.Link>
              </Link>

              {/* UserGeoLocation */}
              <div className={`${styles['nvn--navbar-con-menu-desktop']}`}>
                <UserGeoLocation />
              </div>

              {/* NavDropdown - Language Select */}
              {/* <LanguageSelect /> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <FloatingMenu profilePictureUrl={profilePictureUrl} status={status} />
        <Loading isLoading={loggingOut} isLoadingText={Trans('LoggingOut')}/>
      </>
  );
}

// PropTypes
MainNavBar.propTypes = {
  centralPoint: PropTypes.string,
}

export default MainNavBar;