// src/config/copyrightNote.js
export const  copyNote = { 
    // copyright: '\u00A9',
    copyright: 'COPYRIGHTS © ',
    year: new Date().getFullYear(),
    note: process.env.NEXT_PUBLIC_APP_COPYRIGHT_NOTE,
};

export const  appVer = { 
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    release: process.env.NEXT_PUBLIC_APP_RELEASE,
};
