// src/components/AddBookFooter/index.js
import React, { useState, useCallback } from "react";
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';

import styles from './css/add-book-footer.module.css';

export const AddBookFooter = () => {
    const router = useRouter();

    const handleAddBookClick = () => {
        router.push('/moj-zbior/dodaj-ksiazke'); // Przekieruj na stronę logowania
    }; 

    const handleForBusinessesClick = () => {
        router.push('/dla-firm'); // Przekieruj na stronę logowania
    }; 

    return (
        <>
            <section className={styles["abf--sec"]}>
                <div className={styles["abf--con"]}>
                    <div className={styles["abf--f-p"]}>
                        <div className={styles["abf--f-p-t-p"]}>
                            <FormattedMessage
                                id = "PromoteBusiness"
                                defaultMessage="Promuj swój biznes za darmo"
                            />
                        </div>
                        <div className={styles["abf--f-p-t-a-c"]}>       
                            <FormattedMessage
                                id = "EasilyAddBooksYouOwn"
                                defaultMessage="Łatwo dodawaj książki, które posiadasz"
                            />  
                        </div>
                    </div>
                    <div className={styles["abf--f-s"]}>
                        <button className={styles["abf--f-s-a-c"]} onClick={()=> {handleAddBookClick()}}>
                            <FormattedMessage
                                id = "AddBook"
                                defaultMessage="Dodaj książkę"
                            />
                        </button>
                        <button className={styles["abf--f-s-r-c"]} onClick={()=> {handleForBusinessesClick()}}>         
                            <FormattedMessage
                                id = "WorkWithUs"
                                defaultMessage="Współpracuj z nami"
                            />
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}
