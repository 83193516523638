// src/lib/api/extractBookInformationDetails.js
import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3 });

export async function extractBookInformationDetails({ id, isbn }) {
  // console.log("extractBookInformationDetails => ", id)
  // try {
  //   // Odłołanie do lokalnego API Next.js
  //   const response = await axios.get(`${process.env.NEXT_PUBLIC_APP_URL}/api/book-information/book-by-id`, {
  //     params: { id }
  //   });
  //   return response.data;
  // } catch (error) {
  //   console.error('Błąd w extractBookInformationDetails: ', error);
  //   return null;
  // }
  try {
    let endpoint;
    let params = {};

    if (id) {
      endpoint = '/api/book-information/book-by-id';
      params.id = id;
    } else if (isbn) {
      endpoint = '/api/book-information/book-by-isbn';
      params.isbn = isbn;
    }

    const response = await axios.get(`${process.env.NEXT_PUBLIC_APP_URL}${endpoint}`, {
      params: params
    });

    return response.data;
  } catch (error) {
    console.error('Błąd w extractBookInformationDetails: ', error);
    return null;
  }
}
