// src/navigation/FloatingMenu/index.js
import React, { useState, useCallback } from 'react';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import styles from './css/floating-menu.module.css';

import { IconPaths } from '@/constants';
import { genAltText } from '@/utils';
import { ModalSearchBox } from '@/components/_common/ModalSearchBox';
import fetchBookOffers from '@//lib/redux/bookOffers/ApiActionCreator';
import { useGeolocation } from '@/contexts/GeolocationContext';

const FloatingMenu = ({profilePictureUrl, status}) => {
    const [isOpen, setIsOpen] = useState(true);
    const router = useRouter();
    const [modalSearchBoxIsShown, setModalSearchBoxIsShown] = useState(false);
    const { coordinates } = useGeolocation();
    const dispatch = useDispatch();

    const handleDashboardClick = () => {
        if (status === "authenticated") {
            router.push('/panel'); // Przekieruj na stronę logowania
        }else {
            router.push('/zaloguj'); // Przekieruj na stronę logowania
        }
    };

    const handleFavoritesClick = () => {
        router.push('/ulubione'); // Przekieruj na stronę ulubione
    };

    const handleModalSearchBoxClick = useCallback(() => {
        setModalSearchBoxIsShown(current => !current);
    }, []);

    const handleMyLocationsClick = () => {
        router.push('/moje-lokalizacje'); // Przekieruj na stronę moje-lokalizacje
    };

    const handleMyOffersClick = () => {
        router.push('/moj-zbior'); // Przekieruj na stronę moj-zbior
    };

    const isActive = (path) => {
        return router.pathname === path;
    };

    const intl = useIntl();
    const _noSearchDataIntl = intl.formatMessage({ id: "NoSearchData" });
    // const _noInformationFoundIntl = intl.formatMessage({ id: "NoInformationFound" });
    const onSearch = (searchTerm) => {
      // console.log(`Wyszukiwanie: ${searchTerm}`);
      if ( searchTerm.length === 0 ) {
          toast.error(_noSearchDataIntl);
          // dispatch(fetchBookInformation(coordinates.latitude, coordinates.longitude, searchTerm, 1));

          // Aktualizuj URL, dodając parametr wyszukiwania
          router.push(`/?wyszukaj=${encodeURIComponent(searchTerm)}`);
      }else {
          // toast.error(_noInformationFoundIntl);
          // dispatch(fetchBookInformation(coordinates.latitude, coordinates.longitude, searchTerm, 1));
          dispatch(fetchBookOffers(coordinates.latitude, coordinates.longitude, searchTerm, 1));

          // Aktualizuj URL, dodając parametr wyszukiwania
          router.push(`/?wyszukaj=${encodeURIComponent(searchTerm)}`);
          // setInputValue('');
      }
    };

    const toggleCloseMenu = () => setIsOpen(!isOpen);

    return (
        <>
            <div className={isOpen ? styles["ft--con"] : `${styles['ft--con']} ${styles['close']}`}>
                <div className={`${styles['ft--con-menu-ico']}`}>
                    <Image  
                        src={IconPaths.navbarTogglerCloseIcon} 
                        alt={genAltText(IconPaths.navbarTogglerCloseIcon)}
                        width={12}
                        height={12}
                        onClick={toggleCloseMenu}
                    />
                </div>
                <div className={`${styles['ft--con-menu']}`}>
                    {/* MyOffers */}
                    {/* <div className={`${styles['ft--con-menu-item']} ${styles['ft--con-menu-item-ftd']}`} onClick={() => handleMyOffersClick()}> */}
                    <div className={isActive('/moj-zbior') ? `${styles['ft--con-menu-item']} ${styles['ft--con-menu-item-active']}` : styles['ft--con-menu-item']} onClick={() => handleMyOffersClick()}>
                        <Image  
                            src={IconPaths.myOffersBlackIcon} 
                            alt={genAltText(IconPaths.myOffersBlackIcon)}
                            width={24}
                            height={24}
                        /> 
                        <FormattedMessage id="MyOffers" defaultMessage="Moj zbiór" />
                    </div>
                    {/* MyLocations */}
                    <div className={isActive('/moje-lokalizacje') ? `${styles['ft--con-menu-item']} ${styles['ft--con-menu-item-active']}` : styles['ft--con-menu-item']} onClick={() => handleMyLocationsClick()}> {/* onClick={() => handleGeolocationClick()} */}
                        <Image  
                            src={IconPaths.browseBlackIcon} 
                            alt={genAltText(IconPaths.browseBlackIcon)}
                            width={24}
                            height={24}
                        /> 
                        {/* <FormattedMessage id="Localize" defaultMessage="Lokalizuj" /> */}
                        <FormattedMessage id="Locations" defaultMessage="Lokalizacje" />
                    </div>
                    {/* Search */}
                    <div className={`${styles['ft--con-menu-item']}`} onClick={()=> {handleModalSearchBoxClick()}}>
                    {/* <div className={modalSearchBoxIsShown ? `${styles['ft--con-menu-item']} ${styles['ft--con-menu-item-active']}` : styles['ft--con-menu-item']} onClick={() => handleModalSearchBoxClick()}> */}
                        <Image  
                            src={IconPaths.searchIcon} 
                            alt={genAltText(IconPaths.searchIcon)}
                            width={24}
                            height={24}
                        /> 
                        <FormattedMessage id="Search" defaultMessage="Szukaj" />
                    </div>
                    {/* Favorites */}
                    <div className={isActive('/ulubione') ? `${styles['ft--con-menu-item']} ${styles['ft--con-menu-item-active']}` : styles['ft--con-menu-item']} onClick={() => handleFavoritesClick()}>
                        <Image  
                            src={IconPaths.favoritesIcon} 
                            alt={genAltText(IconPaths.favoritesIcon)}
                            width={24}
                            height={24}
                        /> 
                        <FormattedMessage id="Favorites" defaultMessage="Ulubione" />
                    </div>
                    {/* Account */}
                    <div className={isActive('/panel') ? `${styles['ft--con-menu-item']} ${styles['ft--con-menu-item-active']}` : styles['ft--con-menu-item']} onClick={() => handleDashboardClick()}>
                        {/* <Image  
                            src={status === "authenticated" && profilePictureUrl ? profilePictureUrl : IconPaths.accountIcon} 
                            alt={genAltText(IconPaths.accountIcon)}
                            width={24}
                            height={24}
                            className={status === "authenticated" && profilePictureUrl ? `${styles['ft--con-menu-acount-img']}` :''}
                        /> */}
                        <img  
                            src={status === "authenticated" && profilePictureUrl ? profilePictureUrl : IconPaths.accountIcon} 
                            alt={genAltText(IconPaths.accountIcon)}
                            width={24}
                            height={24}
                            className={status === "authenticated" && profilePictureUrl ? `${styles['ft--con-menu-acount-img']} ${styles['no-hover-effect']}` : `${styles['ft--con-menu-acount-img']}`}
                        />
                        <FormattedMessage id="Dashboard" defaultMessage="Panel" />
                    </div>
                </div>
            </div>
            {/* Wpisz czego szukasz */}
            {          
                modalSearchBoxIsShown && 
                <ModalSearchBox 
                    changeIsShownState={setModalSearchBoxIsShown} 
                    befeoreSearchInputFormattedMessageId={'EnterTitleAuthorPublisherISBN'}
                    befeoreSearchInputFormattedDefaultMessage={'Wpisz tytuł, autora, isbn ...'}
                    placeholderSearchInputText={'WhatAreYouLookingForPlaceholder'}
                    afterSearchInputFormattedMessageId={'StartSearchingForYourNewFavoriteBook'}
                    afterSearchInputFormattedDefaultMessage={'Rozpocznij wyszukiwanie swojej nowej ulubionej książki'}
                    backToPageFormattedMessageId={'HomePage'} 
                    backToPageFormattedDefaultMessage={'Wróć to strony głównej'} 
                    backBtnClickPath={'/'}
                    onSearchClick={onSearch}
                    withQrCodeScanner={false}
                />
            }
        </>
    );
};

export default FloatingMenu;

// PropTypes
FloatingMenu.propTypes = {
    profilePictureUrl: PropTypes.string,
    status: PropTypes.string,
};