// src/lib/redux/userBookOffers/ApiActionCreator.js
import { fetchData, fetchSuccess, fetchError } from './ApiAction';
import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3 });
const fetchUserBookOffers = (userId,  searchPhrase, sortField, sortDirection, page) => async dispatch => {
  dispatch(fetchData());
  
  new Promise((resolve, reject) => {
    axios
      .get(`/api/user-book-offers`, {
        params: { 
          userId,
          searchPhrase, //title, author, isbn ...
          sortField,
          sortDirection, //asc, desc
          page
        },
      })
      .then((response) => {
        if (response.data) {
          dispatch(fetchSuccess(response.data));
        }
        resolve(response);
      })
      .catch((error) => {
        console.error('Błąd w fetchUserBookOffers: ', error);
        dispatch(fetchError(error));
        reject(error);
      });
  });
};

export default fetchUserBookOffers;
