// src/components/_common/Pagination/index.js
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';

import styles from './css/pagination.module.css';

import { IconPaths } from '../../../constants';
import { genAltText } from '../../../utils';

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    const [maxVisiblePages, setMaxVisiblePages] = useState(5);

    useEffect(() => {
        // Zaktualizuj maxVisiblePages na podstawie szerokości ekranu
        const updateMaxVisiblePages = () => {
            setMaxVisiblePages(window.innerWidth > 768 ? 5 : 3);
        };

        updateMaxVisiblePages();
        window.addEventListener('resize', updateMaxVisiblePages);
        return () => window.removeEventListener('resize', updateMaxVisiblePages);
    }, []);

    const paginationItems = () => {
        const pages = [];

        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = startPage + maxVisiblePages - 1;

        // Dostosuj zakres, jeśli przekracza liczbę stron
        if (endPage > nPages) {
            endPage = nPages;
            startPage = nPages - maxVisiblePages + 1 > 0 ? nPages - maxVisiblePages + 1 : 1;
        }

        // Dodaj kropki i pierwszą stronę, jeśli jest więcej stron przed startPage
        if (startPage > 1) {
            pages.push(1);
            if (startPage > 2) {
                pages.push("...");
            }
        }

        // Dodaj numery stron
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        // Dodaj kropki i ostatnią stronę, jeśli jest więcej stron po endPage
        if (endPage < nPages) {
            if (endPage < nPages - 1) {
                pages.push("...");
            }
            pages.push(nPages);
        }

        return pages;
    };

    const onEllipsisClick = (index) => {
        const newPages = paginationItems();
        if (newPages[index] === "...") {
            const isLeftEllipsis = index === 1;
            // Znajdź najbliższą stronę do lewej lub prawej od kropek
            const newCurrentPage = isLeftEllipsis ? newPages[index - 1] + 1 : newPages[index + 1] - 1;
            setCurrentPage(newCurrentPage);
        }
    };    

    const renderPageNumbers = paginationItems().map((pgNumber, index) => (
        <div key={index} className={`${styles['pag--pages-item']} ${currentPage === pgNumber ? styles['active'] : ''}`} style={{ cursor: 'pointer' }} >
            {pgNumber !== "..." ? (
                <div onClick={() => setCurrentPage(pgNumber)} className={styles["pag--pages-item-t"]}>
                    {pgNumber}
                </div>
            ) : (
                <div onClick={() => onEllipsisClick(index)} className={styles["pag--pages-item-ellipsis"]}>
                    {pgNumber}
                </div>
            )}
        </div>
    ));

    const nextPage = () => {
        if (currentPage !== nPages) setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1);
    };

    return (
        <div className={styles["pag--sec"]}>
            <div className={styles["pag--prev"]} onClick={prevPage} style={{ cursor: 'pointer' }}>
                <Image src={IconPaths.arrowPreviousIcon} alt={genAltText(IconPaths.arrowPreviousIcon)} width={18} height={18} className={styles["pag--ico"]} />
            </div>
            <div className={styles["pag--pages"]}>
                {renderPageNumbers}
            </div>
            <div className={styles["pag--nxt"]} onClick={nextPage} style={{ cursor: 'pointer' }}>
                <Image src={IconPaths.arrowNextIcon} alt={genAltText(IconPaths.arrowNextIcon)} width={18} height={18} className={styles["pag--ico"]} />
            </div>
        </div>
    );
};

Pagination.propTypes = {
    nPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
};

export default Pagination;
