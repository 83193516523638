// src/components/_common/NoResulats/index.js
import React from "react";
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import PropTypes from 'prop-types';

import styles from './css/no-results-content.module.css';

import { genAltText } from '@/utils';

export const NoResultsContent = ({resultImage, resultHeader, resultText, resultButtonLable = null, resultHandleButtonClick = null, verticalLayout = false}) => {
    return (
        <div className={verticalLayout ? styles["no-results-content--wrp-verticalLayout"] : styles["no-results-content--wrp"]}>
            <div className={verticalLayout ? styles["no-results-content--wrp-r-verticalLayout"] : styles["no-results-content--wrp-r"]}>
                <div className={verticalLayout ? styles["no-results-content--wrp-r-i-verticalLayout"] : styles["no-results-content--wrp-r-i"]}>
                    <Image 
                        src={resultImage} 
                        alt={genAltText(resultImage)} 
                        className={verticalLayout ? styles["no-results-content--wrp-r-i-img-verticalLayout"] : styles["no-results-content--wrp-r-i-img"]} 
                        width={335} height={335} 
                    />
                </div>
                <div className={verticalLayout ? styles["no-results-content--wrp-r-i-verticalLayout"] : styles["no-results-content--wrp-r-i"]}>
                    <div className={verticalLayout ? styles["no-results-content--wrp-r-i-g-verticalLayout"] : styles["no-results-content--wrp-r-i-g"]}>
                        <div className={verticalLayout ? styles["no-results-content--wrp-r-i-g-hdr-verticalLayout"] : styles["no-results-content--wrp-r-i-g-hdr"]}>
                            <FormattedMessage id={resultHeader} />
                            <span className={verticalLayout ? styles["no-results-content--wrp-r-i-g-t-verticalLayout"] : styles["no-results-content--wrp-r-i-g-t"]}>
                                <FormattedMessage id={resultText} />
                            </span>
                        </div>
                        { 
                            resultButtonLable && resultHandleButtonClick ?
                            <div className={verticalLayout ? styles["no-results-content--wrp-r-i-g-btn-verticalLayout"] : styles["no-results-content--wrp-r-i-g-btn"]}>
                                <button className={verticalLayout ? styles["no-results-content--wrp-r-i-g-btn-d-verticalLayout"] : styles["no-results-content--wrp-r-i-g-btn-d"]} onClick={()=> resultHandleButtonClick()}>
                                    <FormattedMessage id={resultButtonLable} /> 
                                </button>
                            </div>
                            : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

// PropTypes
NoResultsContent.propTypes = {
    resultImage: PropTypes.string,
    resultHeader: PropTypes.string,
    resultText: PropTypes.string,
    resultButtonLable: PropTypes.string,
    resultHandleButtonClick: PropTypes.func,
    verticalLayout: PropTypes.bool,
};