// src/components/_common/Layout/Layout.js
import React, { useState, useEffect, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { useRouter } from 'next/router';
// import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { useDispatch, useSelector } from 'react-redux';
import { useSession } from 'next-auth/react';

import styles from './css/layout.module.css';

import { initGA, logPageView } from '@/utils/analytics'
import Loading from "@/components/_common/Loading/Loading";
import MainNavBar from '@/navigation/MainNavBar/MainNavBar';
import { Footer } from '@/components/_common/Footer';
import { Trans } from '@/utils/intlTranslations';
// import fetchBookInformation from '@/lib/redux/bookInformation/ApiActionCreator';
// import fetchBookOffers from '@//lib/redux/bookOffers/ApiActionCreator';
import { updateSession } from '@/lib/redux/sessionReducer/Actions';
import { useGeolocation } from '@/contexts/GeolocationContext';
// import { useLanguage } from '@/contexts/LanguageContext';
import CookieConsentPopupNotice from '@/components/_common/CookieConsentPopupNotice';
import DevelopmentPopupNotice from '@/components/_common/DevelopmentPopupNotice';

function Layout({ cssLayoutContainer = 'layout-container', cssLayoutContent = 'layout-main-content', loading = false, extended = false, hideSearch = false, barColor = '#FBFBFF', ...props }) {
  // const {locale} = useLanguage();
  const { isGeolocation } = useGeolocation();
  const [loadingRouterPush, setLoadingRouterPush] = useState(false);
  const [showPopups, setShowPopups] = useState(false);

  // Selektor Reduxa
  const { data: session } = useSession();
  // const { coordinates } = useGeolocation();

  const dispatch = useDispatch();

  // Selektor Reduxa
  const { data: bookInformationData, loading:  bookInformationIsLoading } = useSelector((state) => state.bookInformation);
  const { data: bookOffersData, loading:  bookOffersIsLoading } = useSelector((state) => state.bookOffers);
  const sessionData = useSelector((state) => state.session.user);

  const router = useRouter();
  const searchParams = useMemo(() => new URLSearchParams(router.asPath.split(/\?/)[1]), [router.asPath]);

  const mapSessionToSessionData = (userSession) => {
    if (!userSession || !userSession.user) {
        return null; // lub zwróć pusty obiekt {}, w zależności od preferowanego zachowania
    }

    const { user } = userSession;

    const sessionData = {
        id: user.id,
        name: user.name,
        email: user.email,
        emailVerified: user.emailVerified,
        picture: user.picture,
        first_name: user.first_name,
        last_name: user.last_name,
        middle_name: user.middle_name,
        short_name: user.short_name,
        name_format: user.name_format,
        created_at: user.created_at,
        updated_at: user.updated_at,
    };

    return sessionData;
  }

  // Aktualizacja stanu w sessionReducer, dispatch => updateSession, window.scrollTo
  useEffect(() => {
    if (session && !sessionData) {
        // Zaktualizuj stan sesji w redux
        const sessionToSessionData = mapSessionToSessionData(session);
        dispatch(updateSession(sessionToSessionData));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    // scroll to the top of the browser window when changing route
    // the window object is a normal DOM object and is safe to use in React.
  }, [session, sessionData, dispatch]);

  // dispatch => fetchBookOffers
  // useEffect(() => {
  //   if (coordinates.latitude &&  coordinates.longitude){
  //     // dispatch(fetchBookInformation(coordinates.latitude, coordinates.longitude, searchParams, 1));
  //     dispatch(fetchBookOffers(coordinates.latitude, coordinates.longitude, searchParams, 1));
  //   }
  // }, [coordinates, dispatch]);

  // initGA, logPageView
  useEffect(() => {
    // if (!window.GA_INITIALIZED) {
    //   //wyłączone na czas testów https://pagespeed.web.dev
    //   initGA();
    //   window.GA_INITIALIZED = true;
    // }
    // //wyłączone na czas testów https://pagespeed.web.dev
    // logPageView();

    // var element = document.getElementById("footer");
    // element.classList.add("mystyle");
    // console.log("searchParams => ", searchParams)
  }, []);

  useEffect(() => {
    const handleStart = () => setLoadingRouterPush(true);
    const handleComplete = () => setLoadingRouterPush(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
        router.events.off('routeChangeStart', handleStart);
        router.events.off('routeChangeComplete', handleComplete);
        router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  // Opóźnienie wyświetlenia pop-upów do momentu, gdy strona zostanie w pełni załadowana
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopups(true);
    }, 3000); // Opóźnienie 3 sekundy
  
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Head>
        <title>{props.metaTags?.title || "Znajdziesz tu każdą książkę - " + process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME}</title>

        <meta charSet="utf-8" data-react-helmet="true" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Metadane Open Graph */}
        <meta property="og:title" content={props.metaTags?.ogTitle || "Znajdziesz tu każdą książkę - " + process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME} />
        <meta property="og:description" content={props.metaTags?.ogDescription || "Twoje najnowocześniejsze źródło informacji na temat wszystkiego, co lokalne - " + process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME} />
        <meta property="og:image" content={props.metaTags?.ogImage || process.env.NEXT_PUBLIC_APP_URL + ImagePaths.adsImg} />
        <meta property="og:image:url" content={props.metaTags?.ogImage || process.env.NEXT_PUBLIC_APP_URL + ImagePaths.adsImg} />
        <meta property="og:image:secure_url" content={props.metaTags?.ogImage || process.env.NEXT_PUBLIC_APP_URL + ImagePaths.adsImg} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="og:url" content={props.metaTags?.ogUrl || process.env.NEXT_PUBLIC_APP_URL} />
        <meta property="og:site_name" content={process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME} />

        <meta property="og:locale" content="pl_PL" />
        <meta property="og:locale:alternate" content="en_PL" />
        <meta property="og:locale:alternate" content="de_PL" />
        <meta property="og:locale:alternate" content="uk_PL" />
        <meta property="og:type" content="website" />
        {/* <meta property="og:type" content="book" /> */}

        {/* Google Site Verification */}
        {/* buuki.pl */}
        <meta name="google-site-verification" content="9AvEHXIWeRkYILotcvR-PaiKflhzVORSKxSH6mT3df0" />
        {/* szukajksiazki.pl */}
        <meta name="google-site-verification" content="zxs5qeq6bKRj9UyJBhTQhPVg8NM7WMUsfrI6gdjfT6Q" />
        
      </Head>
      <Helmet>
        <title>{props.metaTags?.title || "Znajdziesz tu każdą książkę - " + process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME}</title>

        {/* <meta name="title" content={metaTitle} data-react-helmet="true" /> */}

        <link rel="canonical" href={props.metaTags.ogUrl} data-react-helmet="true" />
        <link rel="manifest" href="/manifest.json" />

        <meta name="author" content="BUUKI" data-react-helmet="true" />
        <meta name="keywords" content="szukajksiazki.pl, buuki.pl, książki, lokalne księgarnie, znajdź księgarnię, zakupy książek, literatura, bestsellery, przewodniki księgarni, zakup książek online, nowe wydania" data-react-helmet="true" />
        <meta name="robots" content="index,follow" data-react-helmet="true" />

        <link rel="icon" href="/assets/ico/favicon.ico" />
        <link rel="icon" sizes="16x16" href="/assets/ico/favicon16.ico" />
        <link rel="icon" sizes="24x24" href="/assets/ico/favicon24.ico" />
        <link rel="icon" sizes="32x32" href="/assets/ico/favicon32.ico" />
        <link rel="icon" sizes="48x48" href="/assets/ico/favicon48.ico" />
        <link rel="icon" sizes="57x57" href="/assets/ico/favicon57.ico" />
        <link rel="icon" sizes="60x60" href="/assets/ico/favicon60.ico" />
        <link rel="icon" sizes="64x64" href="/assets/ico/favicon64.ico" />
        <link rel="icon" sizes="72x72" href="/assets/ico/favicon72.ico" />
        <link rel="icon" sizes="76x76" href="/assets/ico/favicon76.ico" />
        <link rel="icon" sizes="96x96" href="/assets/ico/favicon96.ico" />
        <link rel="icon" sizes="114x114" href="/assets/ico/favicon114.ico" />
        <link rel="icon" sizes="144x144" href="/assets/ico/favicon144.ico" />
        <link rel="icon" sizes="160x160" href="/assets/ico/favicon160.ico" />
        <link rel="icon" sizes="192x192" href="/assets/ico/favicon192.ico" />

        {/* <meta name="description" content={props.metaTags.description} data-react-helmet="true" />
        <meta property="og:title" content={props.metaTags.ogTitle} data-react-helmet="true"/>
        <meta property="og:description" content={props.metaTags.ogDescription} data-react-helmet="true" />
        <meta property="og:image" content={props.metaTags.ogImage} data-react-helmet="true" />
        <meta property="og:image:url" content={props.metaTags.ogImage} data-react-helmet="true" />
        <meta property="og:image:secure_url" content={props.metaTags.ogImage} data-react-helmet="true" />
        <meta property="twitter:card" content="summary_large_image" data-react-helmet="true" />
        <meta property="og:url" content={props.metaTags.ogUrl} data-react-helmet="true" /> */}

        {/* <meta property="og:locale" content="pl_PL" data-react-helmet="true" />
        <meta property="og:locale:alternate" content="en_PL" data-react-helmet="true" />
        <meta property="og:locale:alternate" content="de_PL" data-react-helmet="true" />
        <meta property="og:locale:alternate" content="uk_PL" data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />  */}
        {/* <meta property="og:image:width" content="1200" /> */}
        {/* <meta property="og:image:height" content="630" /> */}
      </Helmet>
      <div className={styles[cssLayoutContainer]}>
        <div className={styles[cssLayoutContent]}>
          <MainNavBar hideSearch={hideSearch} barColor={barColor}/>
          <main className={styles[cssLayoutContent]}>
            {props.children}
          </main>
        </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Loading isLoading={loadingRouterPush} isLoadingText={Trans('Processing')}/>
          <Loading isLoading={bookInformationIsLoading} isLoadingText={Trans('Processing')}/>
          <Loading isLoading={bookOffersIsLoading} isLoadingText={Trans('Searching')}/>
          <Loading isLoading={isGeolocation} isLoadingText={Trans('Geolocation')}/>
          <Footer />
          {showPopups && <CookieConsentPopupNotice />}
          {showPopups && <DevelopmentPopupNotice />}
      </div>
    </>
  )
}

// PropTypes
Layout.propTypes = {
  cssLayoutContainer: PropTypes.string.isRequired,
  cssLayoutContent: PropTypes.string,
  cssLayoutContainer: PropTypes.string,
  loading: PropTypes.func,
  extended: PropTypes.bool,
  hideSearch: PropTypes.bool,
  barColor: PropTypes.string,
  children: PropTypes.node.isRequired, // Oczekiwany jest dowolny węzeł JSX
  sessionData: PropTypes.array,
  metaTags: PropTypes.object,
}

export default Layout;
