import React, { useState, useEffect } from 'react';

const DevelopmentPopupNotice = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [windowWidth, setWindowWidth] = useState(null); // Zmieniono tutaj

    useEffect(() => {
        // Inicjalizacja szerokości okna i nasłuchiwanie zmiany rozmiaru
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        setWindowWidth(window.innerWidth);

        const hasSeenPopup = localStorage.getItem('hasSeenDevPopup');
        if (!hasSeenPopup) {
            setIsPopupVisible(true);
            localStorage.setItem('hasSeenDevPopup', 'true');
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const hasSeenPopup = localStorage.getItem('hasSeenDevPopup');

        if (!hasSeenPopup) {
        setIsPopupVisible(true);
        localStorage.setItem('hasSeenDevPopup', 'true');
        }
    }, []);

    const handleClose = () => {
        setIsPopupVisible(false);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    if (!isPopupVisible) return null;

    return (
        <div style={popupStyle(windowWidth)}>
            <div style={popupContentStyle}>
            <h2>Informacja o Fazie Rozwoju</h2>
            <p>Uwaga: Serwis {process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME} jest obecnie w fazie rozwoju i testów. Funkcjonalności mogą ulec zmianie. Dziękujemy za zrozumienie i cierpliwość. Twoja opinia i doświadczenia są dla nas ważne i pomogą w dalszym ulepszaniu serwisu. Prosimy o zgłaszanie wszelkich uwag i błędów.</p>
            <button
                onClick={handleClose}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={isHovered ? hoverButtonStyle : buttonStyle}
            >
                Zamknij
            </button>
            </div>
        </div>
    );
};

const popupStyle = (windowWidth) => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // backgroundColor: 'rgba(0, 0, 0, 0.7)',
    backgroundColor: 'rgba(64, 70, 106, 0.7)',
    padding: '20px',
    zIndex: 999999,
    borderRadius: '10px',
    maxWidth: windowWidth < 768 ? '95%' : '50%', // Szerokość dla urządzeń mobilnych
    minWidth: '340px', // Minimalna szerokość
    boxSizing: 'border-box',
    fontFamily: 'Asap',
    fontStyle: 'normal',
    boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.4)',
});

const popupContentStyle = {
    backgroundColor: 'white',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '14px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
};

const buttonStyle = {
    padding: '10px 40px',
    borderRadius: '14px',
    backgroundColor: '#40466a',
    color: '#FBFBFF',
    border: 'none',
    cursor: 'pointer',
    marginTop: '10px',
    transition: 'all 0.3s',
};
  
const hoverButtonStyle = {
    ...buttonStyle,
    // border: '2px solid #ABC4F0',
    backgroundColor: '#000732', // Użyj backgroundColor
    color: '#FBFBFF',
};

export default DevelopmentPopupNotice;
