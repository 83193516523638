// src/lib/api/refreshSession.js
export async function refreshSession() {
    try {
        const response = await fetch('/api/auth/session', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', // ważne dla obsługi ciasteczek (cookies)
        });

        if (response.ok) {
            const updatedSession = await response.json();
            // Zaktualizuj stan sesji w aplikacji
            // updatedSession.user
        } else {
            console.error('Błąd podczas odświeżania sesji');
        }   
    } catch (error) {
        console.error('Błąd w extractActivityDetails: ', error);
        return null;
    }
};
