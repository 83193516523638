// src/constants/mappings.js

import { Colors, IconPaths } from './';
import { YourResults } from '@/components/YourResults';

// 
// Wspólny obiekt konfiguracyjny
// 
const enterpriseActivitiesConfig = {
  'Book': {
    transId: 'Book',
    type: 'book',
    buukiCategoryId: 1,
    titleTranslations: { pl: "Książki", en: "Books", de: "...", uk: "..." },
    pathTranslations: { pl:'/ksiazki', en:'/books', de:'/', uk:'/' },
    singleFormTranslations: { pl: "Książka", en: "Book", de: "...", uk: "..." },
    singleFormPaths: { pl:'/ksiazka', en:'/book', de:'/', uk:'/' },
    bg: Colors.featuredBgColor,
    icon: IconPaths.promotionsIcon,
    component: YourResults
  },
  'Magazines': {
    transId: 'Magazines',
    type: 'magazines',
    buukiCategoryId: 1,
    titleTranslations: { pl: "Magazyny", en: "Magazines", de: "...", uk: "..." },
    pathTranslations: { pl:'/magazyny', en:'/magazines', de:'/', uk:'/' },
    singleFormTranslations: { pl: "Magazyn", en: "Magazine", de: "...", uk: "..." },
    singleFormPaths: { pl:'/magazyn', en:'/magazine', de:'/', uk:'/' },
    bg: Colors.featuredBgColor,
    icon: IconPaths.promotionsIcon,
    // component: YourResults
  },
  'Ebooks': {
    transId: 'Ebooks',
    type: 'ebooks',
    buukiCategoryId: 1,
    titleTranslations: { pl: "Ebooks", en: "Ebooks", de: "...", uk: "..." },
    pathTranslations: { pl:'/ebooks', en:'/ebooks', de:'/', uk:'/' },
    singleFormTranslations: { pl: "Ebook", en: "Ebook", de: "...", uk: "..." },
    singleFormPaths: { pl:'/ebook', en:'/ebook', de:'/', uk:'/' },
    bg: Colors.featuredBgColor,
    icon: IconPaths.promotionsIcon,
    // component: Books
  },
  'Audiobooks': {
    transId: 'Audiobooks',
    type: 'audiobooks',
    buukiCategoryId: 1,
    titleTranslations: { pl: "Audiobooks", en: "Audiobooks", de: "...", uk: "..." },
    pathTranslations: { pl:'/audiobooks', en:'/audiobooks', de:'/', uk:'/' },
    singleFormTranslations: { pl: "Audiobooki", en: "Audiobooki", de: "...", uk: "..." },
    singleFormPaths: { pl:'/audiobook', en:'/audiobook', de:'/', uk:'/' },
    bg: Colors.featuredBgColor,
    icon: IconPaths.promotionsIcon,
    // component: Books
  },
  'Others': {
    transId: 'Others',
    type: 'others',
    buukiCategoryId: 1,
    titleTranslations: { pl: "Inne", en: "Others", de: "...", uk: "..." },
    pathTranslations: { pl:'/inne', en:'/others', de:'/', uk:'/' },
    singleFormTranslations: { pl: "Inne", en: "Other", de: "...", uk: "..." },
    singleFormPaths: { pl:'/inne', en:'/other', de:'/', uk:'/' },
    bg: Colors.featuredBgColor,
    icon: IconPaths.promotionsIcon,
    // component: Books
  },
  // ... dodać pozostałe główne kategorie dla src/navigation/TopNavCategories/index.tsx
};

// 
// Wygenerowanie `enterpriseCategoryItems` na podstawie `enterpriseActivitiesConfig`
// 
export const enterpriseCategoryItems = Object.keys(enterpriseActivitiesConfig).map((key, index) => {
  const config = enterpriseActivitiesConfig[key];
  return {
    id: index + 1,
    title: key,
    icon: config.icon,
    bg: config.bg,
    transId: config.transId,
    buukiCategoryId: config.buukiCategoryId,
    path: config.pathTranslations,
    isFirst: index === 0 // zakładając, że pierwszy element jest zawsze "isFirst"
  };
});

// 
// Wygenerowanie `mappingCategoryIdToPath`, który dla każdego buukiCategoryId przypisuje odpowiedni pathTranslations na podstawie `enterpriseActivitiesConfig`
// 
export const mappingCategoryIdToPath = (() => {
  const mapping = {};

  Object.keys(enterpriseActivitiesConfig).forEach(key => {
    const categoryConfig = enterpriseActivitiesConfig[key];
    mapping[categoryConfig.buukiCategoryId] = categoryConfig.pathTranslations;
  });

  return mapping;
})();

// 
// Wygenerowanie `PATH_COMPONENT_MAP` na podstawie `enterpriseActivitiesConfig`
// 
export const PATH_COMPONENT_MAP = Object.keys(enterpriseActivitiesConfig).reduce((acc, key) => {
  const activity = enterpriseActivitiesConfig[key];
  Object.keys(activity.pathTranslations).forEach(lang => {
    acc[activity.pathTranslations[lang]] = activity.component;
  });
  return acc;
}, {});

// 
// Wygenerowanie `segmentToMetaTags` na podstawie `enterpriseActivitiesConfig`
// Nadpisuje wartość w <title>...</title> przeglądarki
// 
// DynamicRoute => segmentToMetaTags =>  {"ksiazki":"Książki z dowolnej kategorii","books":"Books","":"...","magazyny":"Magazyny","magazines":"Magazines","ebooks":"Ebooks","audiobooks":"Audiobooks","inne":"Inne","others":"Others"}
export const segmentToMetaTags = (() => {
  const metaTagsExceptions = {
    "ksiazki": "Książki z dowolnej kategorii",
    'ksiazka': 'Książka z dowolnej kategorii',
    // Dodaj tutaj inne wyjątki
  };

  const segmentToMetaTags = {};

  Object.values(enterpriseActivitiesConfig).forEach(activity => {
    Object.keys(activity.pathTranslations).forEach(lang => {
      const path = activity.pathTranslations[lang].substring(1); // Usuwamy początkowy '/'
      // Użyj wyjątku, jeśli istnieje, w przeciwnym razie użyj tłumaczenia z konfiguracji
      segmentToMetaTags[path] = metaTagsExceptions[path] || activity.titleTranslations[lang];
    });
  });

  return segmentToMetaTags;
})();

// 
// src/pages/[[...params]].js
// 
// DynamicRoute => validPlainPaths =>  ["ksiazki","books","","magazyny","magazines","ebooks","audiobooks","inne","others"]
export const validPlainPaths = Object.keys(segmentToMetaTags);
// DynamicRoute => validPaths =>  {"ksiazka":{"type":"book","language":"pl","translation":"Książka"},"book":{"type":"book","language":"en","translation":"Book"},"":{"type":"others","language":"uk","translation":"..."},"magazyn":{"type":"magazines","language":"pl","translation":"Magazyn"},"magazine":{"type":"magazines","language":"en","translation":"Magazine"},"ebook":{"type":"ebooks","language":"en","translation":"Ebook"},"audiobook":{"type":"audiobooks","language":"en","translation":"Audiobooki"},"inne":{"type":"others","language":"pl","translation":"Inne"},"other":{"type":"others","language":"en","translation":"Other"}}
export const validPaths = Object.keys(enterpriseActivitiesConfig).reduce((acc, category) => {
  const config = enterpriseActivitiesConfig[category];
  Object.keys(config.singleFormTranslations).forEach(langKey => {
    const pathKey = config.singleFormPaths[langKey].substring(1); // Usunięcie '/' z początku ścieżki
    const type = config.type;
    const language = langKey;
    const translation = config.singleFormTranslations[langKey];

    acc[pathKey] = { type, language, translation };
  });

  return acc;
}, {});





// // Zaimportuj segmentToMeta i validPlainPaths zdefiniowane wcześniej.
// import { segmentToMeta, validPlainPaths } from 'ścieżka/do/twojego/pliku';

// const isValidPath = (fullPath, type) => {
//   // Zakładając, że `fullPath` to string zawierający całą ścieżkę.
  
//   // Dzielimy ścieżkę na segmenty
//   const pathSegments = fullPath.split('/').filter(Boolean); // filter(Boolean) usunie puste stringi

//   // Sprawdzamy, czy każdy segment znajduje się w kluczach `segmentToMeta`
//   return pathSegments.every(segment => validPlainPaths.includes(segment));
// };

// // Następnie ta funkcja może być używana w Twoim pliku [[...params]].js, na przykład:
// export default function Page({ params }) {
//   // Przykład użycia w Next.js page
//   const router = useRouter();
//   const { params: pathArray } = router.query; // lub jakakolwiek logika do uzyskania pełnej ścieżki
  
//   // Sprawdzamy czy ścieżka jest ważna
//   if (!isValidPath(pathArray.join('/'), 'plain')) {
//     // Jeśli ścieżka nie jest ważna, możesz przekierować użytkownika lub zwrócić 404
//   }

//   // Reszta komponentu...
// }
