// src/helpers/debugLog.js

/**
 * Funkcja logująca, która wyświetla dodatkowy identyfikator i podane dane na wybranym poziomie logowania.
 * @param {*} data - Dane do zalogowania.
 * @param {string} level - Poziom logowania: 'log', 'warn', 'error', 'debug'.
 * @param {string} identifier - Identyfikator wywołania do logu (opcjonalny).
 */
function debugLog(identifier = '', level = 'log', ...data) {
  if (process.env.NODE_ENV === 'production') {
    return
  }
  // Tworzenie treści logu
  // const logContent = `Dev Msg => ${identifier} => ${JSON.stringify(data, null, 2)}`;

  // console.log('debugLog => Typ:', typeof data);
  // console.log('debugLog => Czy jest tablicą:', Array.isArray(data));
  // console.log('debugLog => Długość (jeśli to tablica):', Array.isArray(data) ? data.length : 'n/a');
  // console.log('debugLog => Zawartość:', data);

  function safeStringify(obj) {
    const cache = new Set();
    const replacer = (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
          return {}; // Odnaleziono cykliczną referencję, zastąp ją pustym obiektem
        }
        cache.add(value);
      }
      return value;
    };
  
    try {
      return JSON.stringify(obj, replacer);
    } catch (error) {
      console.error('Błąd podczas bezpiecznej serializacji JSON', error);
      return '{}'; // Zwróć pusty obiekt JSON jako zabezpieczenie
    }
  } 

  // console.group();

  // Wybieranie poziomu logowania
  switch (level) {
    case 'debug':
      console.debug('Dev Msg => ', identifier, ' => ', safeStringify(data, null, 2));
      // console.debug('Dev Msg => ', identifier, ' => ', ...data);
      break;
    case 'warn':
      console.warn('Dev Msg => ', identifier, ' => ', safeStringify(data, null, 2));
      break;
    case 'error':
      console.error('Dev Msg => ', identifier, ' => ', safeStringify(data, null, 2));
      break;
    case 'log':
      console.log('Dev Msg => ', identifier, ' => ', safeStringify(data, null, 2));
      break;
    default:
      console.log('Dev Msg => ', identifier, ' => ', safeStringify(data, null, 2));
      break;
  }
}
  
module.exports = debugLog;
  