// src/components/_common/ModalSearchBox/index.js
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { Form, FormControl, Button } from 'react-bootstrap';

import styles from './css/modal-search-box.module.css';

import { Trans } from '@/utils/intlTranslations';
import { ImagePaths, IconPaths } from '@/constants';
import { genAltText } from '@/utils';
import QrCodeScanner from '@/components/_common/QrCodeScanner';

export const ModalSearchBox = ({
        changeIsShownState, 
        befeoreSearchInputFormattedMessageId, 
        befeoreSearchInputFormattedDefaultMessage, 
        backToPageFormattedMessageId,
        placeholderSearchInputText, 
        afterSearchInputFormattedMessageId,
        afterSearchInputFormattedDefaultMessage,
        backToPageFormattedDefaultMessage, 
        backBtnClickPath,
        onSearchClick,
        withQrCodeScanner
}) =>  {
    const [showModal, setShowModal] = useState(true);
    const [searchInputValue, setSearchInputValue] = useState('');

    const handleClose = () => {
        setShowModal(false);
        changeIsShownState(false);
    }

    const handleBackBtnClick = (e) => {
        router.push(backBtnClickPath); // Przekieruj na stronę logowania
    };

    const handleInputChange = (evt) => {
        setSearchInputValue(evt.target.value);
    };

    const handleSearchSubmit = (evt) => {
        evt.preventDefault(); // Zapobiega domyślnej akcji przeglądarki dla formularza, zapobiega odświeżeniu strony
        onSearchClick(searchInputValue);
        setShowModal(false);
        changeIsShownState(false);
    };

    // Przygotowanie dźwięku
    const beepSound = new Audio('/assets/audio/censorship-beep.wav');

    const onNewScanResult = (decodedText, decodedResult) => {
        // handle decoded results here
        // console.log(`Scan result: ${decodedText}`);
        // console.log(`Scan result: ${decodedResult}`);

        // Zrob coś ze zeskanowanym kodem, np. ustaw wartość w polu wyszukiwania
        setSearchInputValue(decodedText);

        beepSound.play();
    };

    return (
      <>
        <Modal show={showModal} onHide={handleClose} className={styles["msb--sec"]}>
            {/* <Modal.Header closeButton className={styles["msb--hdr"]}>
                <Modal.Title></Modal.Title>
            </Modal.Header> */}
            <Modal.Body className={styles["msb--body"]}>
                <CloseButton className={styles["msb--body-c-b"]} onClick={handleClose}/>
                {/* Wpisz lub zeskanuj ISBN Twojej książki */}
                <div className={styles["msb--body-t-u"]}>
                    <FormattedMessage
                        id = {befeoreSearchInputFormattedMessageId}
                        defaultMessage={befeoreSearchInputFormattedDefaultMessage}
                    />
                </div>
                {/* Search */}
                <Form className={"input-group"} onSubmit={handleSearchSubmit}>
                  <FormControl 
                      type="text" 
                      value={searchInputValue} 
                      onChange={handleInputChange} 
                      placeholder={Trans(placeholderSearchInputText)} 
                      className={`mr-sm-2 ${styles['msb--body-s-i']} ${styles['nvn--custom-form-control']}`} 
                  />
                  <Image 
                      src={IconPaths.searchIcon} 
                      alt={genAltText(IconPaths.searchIcon)} 
                      width={20} height={20} 
                      className={`${styles['msb--body-s-ico']} ${styles['nvn--custom-form-control']}`} 
                  />
                  <Button type="submit" variant="outline-success" className={`${styles['msb--body-s-btn']} ${styles['nvn--custom-form-control']}`}>
                      <FormattedMessage id="Search" defaultMessage="Szukaj" />
                  </Button>
                </Form>
                {/* QrCodeScanner */}
                {
                    withQrCodeScanner &&
                    <div className={`${styles['msb--body-s-qr-code-scanner']}`} >
                        <QrCodeScanner
                            fps={10}
                            qrbox={450}
                            disableFlip={false}
                            qrCodeSuccessCallback={onNewScanResult}
                        />
                    </div>
                }
                {/* Uruchom automatyczne skanowanie ISBN */}
                <div className={styles["msb--body-t-d"]}>
                    <FormattedMessage
                        id={afterSearchInputFormattedMessageId}
                        defaultMessage= {afterSearchInputFormattedDefaultMessage}
                    />
                </div>
                {/* starterPageImg */}
                <Image 
                    src={ImagePaths.starterPageImg} 
                    alt={genAltText(ImagePaths.starterPageImg)} 
                    className={styles["msb--body-i"]} 
                    width={998} height={310}
                />
                <Link href={backBtnClickPath} onClick={(e) => handleBackBtnClick(e)} className={styles["msb--body-btn"]} >
                    <FormattedMessage
                        id = {backToPageFormattedMessageId}
                        defaultMessage={backToPageFormattedDefaultMessage}
                    />
                </Link>
            </Modal.Body>
            {/* <Modal.Footer className={styles["msb--footer"]}>
                <Button variant="primary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Save Changes
                </Button>
            </Modal.Footer> */}
        </Modal>
      </>
    );
}

// PropTypes
ModalSearchBox.propTypes = {
    changeIsShownState: PropTypes.func.isRequired,
    befeoreSearchInputFormattedMessageId: PropTypes.string,
    befeoreSearchInputFormattedDefaultMessage: PropTypes.string,
    backToPageFormattedMessageId: PropTypes.string,
    placeholderSearchInputText: PropTypes.string,
    afterSearchInputFormattedMessageId: PropTypes.string,
    afterSearchInputFormattedDefaultMessage: PropTypes.string,
    backToPageFormattedDefaultMessage: PropTypes.string,
    backBtnClickPath: PropTypes.string,
    onSearchClick: PropTypes.func,
    withQrCodeScanner: PropTypes.bool,
};